import { FC } from 'react';
import { Flex } from 'antd';
import { Popover } from 'common/ui';
import { Button } from 'common/ui/_new';
import { LocaleSetting } from '../LocaleSetting';
import { ThemeSetting } from '../ThemeSetting';
import styles from './styles.module.scss';

export const SettingsMenu: FC = () => {
  const content = (
    <Flex vertical>
      <LocaleSetting className={styles.settingsMenuItem} />
      <ThemeSetting className={styles.settingsMenuItem} />
    </Flex>
  );

  return (
    <Popover
      content={content}
      trigger='click'
      align={{
        points: ['tr'],
        offset: [20, 30]
      }}
      arrow={false}
      overlayClassName={styles.overlay}
    >
      <Button icon='settings' type='icon-only' className={styles.button} />
    </Popover>
  );
};
