import { FC, useState } from 'react';
import { Layout } from 'containers';
import { IResultPageProps, ResultPage } from 'common/components/_new';
import { PageErrorHandlerContext } from './helper';
import { PageError, PageErrorBoundaryProps } from './types';

export const PageErrorBoundary: FC<PageErrorBoundaryProps> = ({ showBreadcrumbs = true, children, responseConfig }) => {
  const [pageError, setPageError] = useState<PageError>();

  if (pageError) {
    const { status, ...restPageErrorProps } = pageError;

    const resultProps: IResultPageProps = {
      ...(status in responseConfig && responseConfig[status]),
      ...restPageErrorProps,
      status
    };

    return (
      <Layout showBreadcrumbs={showBreadcrumbs} centered style={{ justifyContent: 'center' }}>
        <ResultPage {...resultProps} />
      </Layout>
    );
  }

  return <PageErrorHandlerContext.Provider value={{ setPageError }}>{children}</PageErrorHandlerContext.Provider>;
};
