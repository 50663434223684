import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'common/hooks';
import { profileSelectors } from 'api/student/profile/profile.selectors';
import { IROLE } from 'types/roles';
import { SettingItemLayout, SettingItemLayoutProps } from '../common';
import { LocaleSelect } from './LocaleSelect';

type LocaleSettingProps = Pick<SettingItemLayoutProps, 'className'>;

export const LocaleSetting: FC<LocaleSettingProps> = ({ className }) => {
  const user: IROLE.RoleObject = useAppSelector(profileSelectors.role);
  const { t } = useTranslation('common', { keyPrefix: 'components.header.settings-menu' });

  /* //! temporal; delete, when localization is completed */
  if (!(process.env.REACT_APP_OLD === 'https://ooc-lms-app-test.geocode.tech' && user.role === 'Students')) {
    return null;
  }

  return (
    <SettingItemLayout label={t('lang.label')} className={className}>
      <LocaleSelect />
    </SettingItemLayout>
  );
};
