import { createApi } from '@reduxjs/toolkit/query/react';
import { getBaseQuery } from 'common/helpers';
import { LessonId } from 'types/entities';
import { ICourseExercise } from 'types/requests/instructor/course.api';
import {
  ICreateExerciseScheduleRequest,
  ICreateLessonScheduleRequest,
  IDeleteExerciseScheduleRequest,
  IDeleteLessonScheduleRequest,
  IEditExerciseScheduleRequest,
  IEditLessonScheduleRequest,
  IExerciseScheduleRequest,
  ILessonScheduleRequest
} from 'types/requests/instructor/schedule.api';

const lessonScheduleTags = (lessonId: LessonId, exercises: ICourseExercise[] | undefined) => {
  if (exercises) {
    return [{ type: 'CourseSchedule', id: lessonId }, exercises?.map((exercise) => ({ type: 'ExerciseSchedule', id: exercise.id }))].flat();
  }
  return [{ type: 'CourseSchedule', id: lessonId }];
};

export const scheduleApi = createApi({
  reducerPath: 'scheduleApi',
  baseQuery: getBaseQuery(),
  tagTypes: ['ExerciseSchedule', 'CourseSchedule'],
  endpoints: ({ query, mutation }) => ({
    getExerciseSchedule: query<IExerciseScheduleRequest.Response, IExerciseScheduleRequest.Params>({
      query: ({ courseId, exerciseId }) => ({
        url: `/api/schedule/trainings/${courseId}/exercises/${exerciseId}/schedule`
      }),
      providesTags: (_, __, arg) => [{ type: 'ExerciseSchedule', id: arg.exerciseId }]
    }),
    createExerciseSchedule: mutation<ICreateExerciseScheduleRequest.Response, ICreateExerciseScheduleRequest.Params>({
      query: ({ courseId, exerciseId, scheduleBody }) => ({
        url: `/api/schedule/trainings/${courseId}/exercises/${exerciseId}/schedule/create`,
        method: 'POST',
        body: scheduleBody
      }),
      invalidatesTags: (_, __, arg) => [{ type: 'ExerciseSchedule', id: arg.exerciseId }]
    }),
    editExerciseSchedule: mutation<IEditExerciseScheduleRequest.Response, IEditExerciseScheduleRequest.Params>({
      query: ({ courseId, exerciseId, scheduleBody }) => ({
        url: `/api/schedule/trainings/${courseId}/exercises/${exerciseId}/schedule/edit`,
        method: 'PUT',
        body: scheduleBody
      }),
      invalidatesTags: (_, __, arg) => [
        { type: 'ExerciseSchedule', id: arg.exerciseId },
        { type: 'CourseSchedule', id: arg.lessonId }
      ]
    }),
    deleteExerciseSchedule: mutation<IDeleteExerciseScheduleRequest.Response, IDeleteExerciseScheduleRequest.Params>({
      query: ({ courseId, exerciseId }) => ({
        url: `/api/schedule/trainings/${courseId}/exercises/${exerciseId}/schedule/delete`,
        method: 'DELETE'
      }),
      invalidatesTags: (_, __, arg) => [
        { type: 'ExerciseSchedule', id: arg.exerciseId },
        { type: 'CourseSchedule', id: arg.lessonId }
      ]
    }),
    getLessonSchedule: query<ILessonScheduleRequest.Response, ILessonScheduleRequest.Params>({
      query: ({ courseId, lessonId }) => `/api/schedule/trainings/${courseId}/lessons/${lessonId}/schedule`,
      providesTags: (_, __, arg) => [{ type: 'CourseSchedule', id: arg.lessonId }, 'CourseSchedule']
    }),
    createLessonSchedule: mutation<ICreateLessonScheduleRequest.Response, ICreateLessonScheduleRequest.Params>({
      query: ({ courseId, lessonId, scheduleBody }) => ({
        url: `/api/schedule/trainings/${courseId}/lessons/${lessonId}/schedule/create`,
        method: 'POST',
        body: scheduleBody
      }),
      //@ts-ignore
      invalidatesTags: (_, __, arg) => lessonScheduleTags(arg.lessonId, arg.exercises)
    }),
    editLessonSchedule: mutation<IEditLessonScheduleRequest.Response, IEditLessonScheduleRequest.Params>({
      query: ({ courseId, lessonId, scheduleBody }) => ({
        url: `/api/schedule/trainings/${courseId}/lessons/${lessonId}/schedule/edit`,
        method: 'PUT',
        body: scheduleBody
      }),
      //@ts-ignore
      invalidatesTags: (_, __, arg) => lessonScheduleTags(arg.lessonId, arg.exercises)
    }),
    deleteLessonSchedule: mutation<IDeleteLessonScheduleRequest.Response, IDeleteLessonScheduleRequest.Params>({
      query: ({ courseId, lessonId }) => ({
        url: `/api/schedule/trainings/${courseId}/lessons/${lessonId}/schedule/delete`,
        method: 'DELETE'
      }),
      //@ts-ignore
      invalidatesTags: (_, __, arg) => lessonScheduleTags(arg.lessonId, arg.exercises)
    })
  })
});

export const {
  useGetExerciseScheduleQuery,
  useCreateExerciseScheduleMutation,
  useEditExerciseScheduleMutation,
  useGetLessonScheduleQuery,
  useDeleteExerciseScheduleMutation,
  useCreateLessonScheduleMutation,
  useEditLessonScheduleMutation,
  useDeleteLessonScheduleMutation
} = scheduleApi;
