import { LANGUAGES } from 'i18n/constants';

export const localizePersonName = (
  {
    firstName,
    lastName
  }: {
    firstName?: string;
    lastName?: string;
  },
  lang: keyof typeof LANGUAGES | string
): string => {
  if (lang === 'ru') {
    return [lastName, firstName].join(' ');
  }
  return [firstName, lastName].join(' ');
};
