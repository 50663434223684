import { DateTime, Id } from 'types/common';

export type AccountId = Id;

//* Lms.Entities.Entities.Accounts.AccountType
// Типы аккаунта
export const enum AccountType {
  New = 0,
  Student = 1,
  Instructor = 2,
  Administrator = 255
}

//* Lms.Utils.Enums.RolePreset
// Пресеты ролей
export const enum RolePresetEnum {
  Preset0 = 0,
  Preset1 = 1,
  Preset2 = 2,
  Preset3 = 3,
  Preset4 = 4,
  Preset7 = 7,
  Preset8 = 8
}

//* Lms.Entities.Entities.Accounts.AccountAction
// Действия пользователя
export type AccountAction = {
  id: Id;
  revision: number;
  created: DateTime;
  createdBy_Id?: Id; // todo: заменить на алиас конкретной сущности
  edited?: DateTime;
  editedBy_Id?: Id; // todo: заменить на алиас конкретной сущности
  accountId: AccountId;
  account: Account;
  lastViewNews: DateTime;
  finalScore?: number;
  rolePreset: RolePresetEnum;
  mailingAgree: boolean;
};

//* Lms.Entities.Entities.Accounts.UserIp
// Сущность представляющая IP-адрес пользователя
export type UserIp = {
  id: Id;
  accountId: Account['id'];
  account: Account;
  ip?: string;
  firstLoginDate: DateTime;
  lastLoginDate: DateTime;
};

//*	Lms.Entities.Entities.Accounts.Account
// Базовый класс для пользователя системы
export type Account = {
  id: AccountId;
  created: Date;
  type: AccountType;
  sid?: string;
  eMail?: string;
  firstname?: string;
  surname?: string;
  patronymic?: string;
  phone?: string;
  lid?: string;
  uid?: string;
  birth?: DateTime;
  workTime?: string;
  about?: string;
  icon?: string;
  tZone?: string;
  tzOk: number;
  online: boolean;
  checked: boolean;
  ok: boolean;
  isForeignPerson: boolean;
  accountAction: AccountAction;
  userIps: UserIp[];
  oneId?: string;
  oneIdHashData?: string;
};

//* Interfaces.DtoModels.AccountRelationDto
// todo: upd
export interface IAccountRelation {
  id: string;
  accountId: AccountId;
  email: string;
  firstname: string;
  surname: string;
  patronymic: string;
}

//* Interfaces.DtoModels.AccountRelationAddDto
// todo: upd
export type AccountRelationAddDto = {
  email: string;
  password: string;
};

//* Interfaces.DtoModels.CurrentStateAccountRelationDto
// todo: upd
export type CurrentStateAccountRelationDto = {
  role: string;
  roleText: string;
  isMainAccount: boolean;
};
