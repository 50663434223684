import { IIconType } from 'common/ui/_new';
import { ExerciseWorkKind } from 'types/entities';

export const getExerciseIconTypeByKind = (kind: ExerciseWorkKind): IIconType => {
  const map: { [K in ExerciseWorkKind]: IIconType } = {
    [ExerciseWorkKind.ANY]: 'file',
    [ExerciseWorkKind.LECTURE]: 'lecture',
    [ExerciseWorkKind.TEST]: 'testing',
    [ExerciseWorkKind.TASK]: 'independent-work'
  };
  return map[kind] || 'file';
};
