/* eslint-disable import/no-named-as-default-member */
import dayjs, { Dayjs } from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import { DateTime } from 'types/common';

dayjs.extend(localizedFormat);

// eslint-disable-next-line @typescript-eslint/ban-types
type Format = 'MMMM YYYY' | 'LL' | (string & {}); // for hints to be shown

export const getLocalizedDate = (value: Dayjs, format: Format): string => {
  const globalLocale = dayjs.locale();
  return dayjs(value).locale(globalLocale).format(format);
};

export const isOpenDatesRange = (dateOpen: DateTime | undefined, dateClose: DateTime | undefined) => {
  const isNullableDateOpen = dateOpen === '0001-01-01T00:00:00Z';
  const isNullableDateClose = dateClose === '9999-12-31T23:59:59.9999999Z';

  return isNullableDateOpen || isNullableDateClose;
};
