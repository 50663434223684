import { createContext, Dispatch, SetStateAction } from 'react';
import { ColorScheme } from './color-theme';

export const ColorSchemeContext = createContext<{
  colorScheme?: ColorScheme;
  setColorScheme: Dispatch<SetStateAction<ColorScheme>>;
}>({
  setColorScheme: () => undefined
});

export const colorSchemesMap = {
  [ColorScheme.DARK]: 'dark',
  [ColorScheme.LIGHT]: 'light'
};
