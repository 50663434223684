import { ComponentProps } from 'react';
import { Flex, Modal as AntModal } from 'antd';
import cn from 'classnames';
import { Icon } from 'common/ui/_new';
import { IModal, IOriginalModal } from './types';
import styles from './styles.module.scss';

const OriginalModal: IOriginalModal = ({
  cancelText,
  cancelButtonProps,
  okText,
  onOk,
  okButtonProps,
  className,
  children,
  ...restProps
}) => {
  const innerProps: ComponentProps<typeof AntModal> = {
    //* overridable:
    closable: true,
    destroyOnClose: true,
    centered: true,
    closeIcon: <Icon type='close' />,
    footer:
      okButtonProps || onOk
        ? (_, { OkBtn, CancelBtn }) => (
            <Flex justify='flex-end' gap={16}>
              {cancelButtonProps && <CancelBtn />}
              <OkBtn />
            </Flex>
          )
        : undefined,
    //* outer:
    ...restProps,
    //* unoverridable:
    cancelText,
    cancelButtonProps,
    okText,
    onOk,
    okButtonProps,
    className: cn(styles.modal, className)
  };

  return <AntModal {...innerProps}>{children}</AntModal>;
};

export const Modal: IModal = Object.assign(OriginalModal, {
  useModal: AntModal.useModal,
  destroyAll: AntModal.destroyAll
});
