import { ComponentPropsWithRef } from 'react';
import { Rate as AntRate } from 'antd';
import cn from 'classnames';
import { IRate } from './types';
import styles from './styles.module.scss';

export const Rate: IRate = (props) => {
  const { className, ...restProps } = props;
  const innerProps: ComponentPropsWithRef<typeof AntRate> = {
    //* overridable:

    //* outer:
    ...restProps,
    //* overridable:
    className: cn(styles.rate, className)
  };

  return <AntRate {...innerProps} />;
};
