import { FC, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { IMenuProps, Menu } from 'common/ui/_new';
import { useAppSelector } from 'common/hooks';
import { profileSelectors } from 'api/student/profile/profile.selectors';
import { ExpandedPanelContext } from '../Layout';
import { getMainNavigationByRole } from '../Navigation';
import styles from './styles.module.scss';

export const VerticalNavigation: FC<IMenuProps> = ({ className }) => {
  const { setIsExpanded } = useContext(ExpandedPanelContext);
  const user = useAppSelector(profileSelectors.role);
  const { t } = useTranslation(user.role, { keyPrefix: 'common.navigation' });
  const items = getMainNavigationByRole(user, t);
  const [openKeys, setOpenKeys] = useState<string[]>([]);

  const onOpenChange = (keys: string[]) => {
    setOpenKeys(keys);
  };

  const onMenuClick = () => {
    setIsExpanded(false);
    setOpenKeys([]);
  };

  return (
    <Menu
      openKeys={openKeys}
      onOpenChange={onOpenChange}
      onClick={onMenuClick}
      items={items}
      mode='inline'
      className={cn(styles.nav, className)}
    />
  );
};
