import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Space } from 'antd';
import { Radar, RadarConfig } from '@ant-design/plots';
import { Paragraph } from 'common/ui/_new';
import { useDefaultMediaQueries } from 'common/hooks';
import { IItemChart } from 'types/entities';
import { getDataForMobile } from './helpers';
import styles from './styles.module.scss';
import styleVar from 'common/styles/vars.scss';

interface IRadarChartProps {
  data: IItemChart[];
  reconfig?: RadarConfig;
}
interface IRadarChartItem {
  data: IItemChart;
}

export const RadarChart: FC<IRadarChartProps> = ({ data, reconfig }) => {
  const { t } = useTranslation('common', { keyPrefix: 'ui.radar-chart' });
  const { isMobile } = useDefaultMediaQueries();

  const checkIsMobile = () => {
    if (isMobile) {
      return {
        data: getDataForMobile(data),
        xField: 'label'
      };
    }
    return {
      data: data,
      xField: 'key'
    };
  };

  const config = useMemo<RadarConfig>(
    () => ({
      ...{
        ...checkIsMobile(),
        yField: 'value',
        meta: {
          value: {
            alias: t('meta.value.alias'),
            min: 0,
            max: 1
          }
        },
        lineStyle: {
          lineWidth: 3,
          stroke: styleVar.colorAccentBlue100,
          fill: styleVar.colorPrimary100,
          fillOpacity: 0.1
        },

        tooltip: {
          customContent: (_, data: IRadarChartItem[]) => {
            const key = data[0]?.data?.key || t('tooltip.key.default');
            const value = data[0]?.data?.value || 0;
            return (
              <Space direction={'vertical'} className={styles.tooltip}>
                <Paragraph>{key}</Paragraph>
                <Paragraph>{t('tooltip.grade', { value })}</Paragraph>
              </Space>
            );
          }
        },
        xAxis: {
          line: null,
          tickLine: null,
          grid: {
            line: {
              style: {
                lineDash: null
              }
            }
          }
        },
        point: {
          size: 2
        }
      },
      ...reconfig
    }),
    // eslint-disable-next-line
    [data, reconfig, isMobile]
  );
  return (
    <div className={styles.wrapper}>
      <Radar {...config} />
    </div>
  );
};
