import { Id } from 'types/common';
import { AttributesField, AttributeTypeEnum } from 'types/entities';

export enum SLIDES_ENUM {
  HTML = '1',
  EXTERNAL_TESTING = '2.101',
  CHECKBOX = '2.2.0',
  RADIO = '2.2.1',
  INPUT_DATE = '2.5.1',
  INPUT_TEXT = '2.5.2',
  INPUT_INTEGER = '2.5.3',
  INPUT_FLOAT = '2.5.4',
  INPUT_BOOLEAN = '2.5.5',
  INPUT_TEXTAREA = '2.5.10',
  ATTACHING_FILE = '2.7',
  TEXT = '2.8',
  ATTRIBUTES = '2.9',
  SCORM = '2.10',
  VIDEO = '6',
  IFRAME = '7',
  IFRAME_VIDEO = '7.6',
  IFRAME_PDF = '7.11',
  PICTURE = '8',
  AUDIO = '9',
  EXTERNAL_IFRAME = '10',
  PDF = '11',
  DOC = '12',
  KINESCOPE = '13'
}

//* Robocode.Areas.Questions.ViewModels.Free.FreeResponseViewModel
export type FreeResponseViewModel = {
  value?: string;
};

//* Robocode.Areas.Questions.ViewModels.Answers.AnswersResponseViewModel
export type AnswersResponseViewModel = {
  options?: Id[];
};

export declare module ISlides {
  // Содержимое ограниченной страницы для пользователя
  export interface Common {
    contentType: number;
    type?: number;
    responseType?: number;
  }

  // HTML
  export interface HTML extends Common {
    content: string;
  }

  interface Options {
    id: string;
    text: string;
    explanation: string;
    select: boolean;
    correct: boolean;
  }

  interface Question {
    id: string;
    text: string;
    label: string;
    answered: boolean;
    options: Options[];
  }

  // CHECKBOX & RADIO
  export interface Choiceable extends Required<Common> {
    isReadonly: boolean;
    remainingTime: string;
    question: Question;
  }

  // INPUT_DATE
  // INPUT_TEXT
  // INPUT_NUMBER
  // INPUT_FLOAT
  // INPUT_BOOLEAN
  // INPUT_TEXTAREA
  export interface FreeQuestion extends Required<Common> {
    text: string;
    value: string;
    isReadonly: boolean;
    remainingTime: string;
    isRequired: boolean;
  }

  // ATTACHING_FILE
  export interface AttachingFile extends Common {
    text: string;
    value: string;
    isReadonly: boolean;
    remainingTime: string;
  }

  // TEXT
  export interface Text extends Common {
    text: string;
  }

  export type Attribute<T extends AttributeTypeEnum = AttributeTypeEnum> = AttributesField<T>;

  // ATTRIBUTES
  export interface Attributes extends Common {
    text?: string;
    attributes: Attribute[];
  }

  interface Downloadable {
    downloadPermission: boolean;
  }

  // SCORM
  export interface Scorm extends Common {
    scormParams: {
      source: string;
      args?: string;
      training: string;
      exercise: string;
    };
    scormUrl: string;
  }

  // VIDEO
  export interface Video extends Common, Downloadable {
    play: boolean;
    url: string;
    player: number;
  }

  // IFRAME
  export interface IFrame extends Common {
    url: string;
  }

  // PICTURE
  export interface Picture extends Common {
    url: string;
  }

  // AUDIO
  export interface Audio extends Common {
    content: string;
    url: string;
  }

  // EXTERNAL_IFRAME
  export interface External extends Common {
    url: string;
  }

  // EXTERNAL_TESTING

  export interface Testing extends Common {
    url: string;
    isDone: boolean;
    results: string;
  }

  // PDF
  export interface PDF extends Common, Downloadable {
    url: string;
  }
  // DOX/DOCX
  export interface DOC extends Common {
    url: string;
  }

  export interface IKinescopeLink {
    quality: string;
    link: string;
    fileSize: string;
  }

  export interface Kinescope extends Common {
    link: string;
    downloadLinks?: IKinescopeLink[];
  }
}

// Список типов вопросов со свободными ответами
export interface IQuestionsFreeType {
  // Идентификатор типа
  id: number;
  // Название типа
  label: string;
  data?: {
    [key: string]: string;
  };
}

interface IExercisePreFinishFeedback {
  // Рейтинг
  rating: number;
  // Значение
  value: string;
  on: boolean;
}

interface IExercisePreFinishSkills {
  // Текст
  text: string;
  // Значение
  value: number;
}

interface IExercisePreFinishQuestionSkills {
  // Название навыка вопроса
  label: string;
  // Оценка навыка вопроса
  mark: string;
}

export interface IExercisePreFinishQuestions extends ISlides.Common {
  // Идентификатор вопроса
  id: string;
  // Название вопроса
  label: string;
  // Значение вопроса
  value: number;
  // Оценка
  mark: string;
  // Массив навыков вопроса
  skills: IExercisePreFinishQuestionSkills[];
}

// Данные для страницы завершения упражнения
export interface IExercisePreFinishDataType {
  // Время
  time: string;
  // Обратная связь
  feedback: IExercisePreFinishFeedback;
  // Массив навыков
  skills: IExercisePreFinishSkills[];
  // Массив вопросов
  questions: IExercisePreFinishQuestions[];
  // Валидация
  validate: boolean;
  // Результат
  result: number;
  // Средний показатель
  average: number;
  // Показать правильный ответ
  isShowCorrectAnswer: boolean;
  // Показать вопрос
  isShowQuestions: boolean;
  // Путь до картинки
  trainingImage: string;
}

interface IExerciseSlidesDuration {
  ticks: number;
  days: number;
  hours: number;
  milliseconds: number;
  minutes: number;
  seconds: number;
  totalDays: number;
  totalHours: number;
  totalMilliseconds: number;
  totalMinutes: number;
  totalSeconds: number;
}

// Список слайдов упражнения
interface IExerciseSlidesType {
  // Идентификатор слайда
  id: string;
  // Отступ
  indent: number;
  // Продолжительность
  duration: IExerciseSlidesDuration;
  // Активна ли страница
  isActivePage: boolean;
}

interface ExerciseSlides<T> {
  exerciseLabel: string;
  exerciseDescription: string;
  pages: T[];
  isShowCorrectAnswerInTest: boolean;
  picture: string;
}

export type IExerciseSlidesBody = ExerciseSlides<Omit<IExerciseSlidesType, 'duration' | 'indent'>>;
export type IExerciseSlideResponse = ExerciseSlides<IExerciseSlidesType>;
